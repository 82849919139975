<template>
  <div class="bank--info" v-if="bank && bank.id">
    <div class="bank--info-image m-auto">
      <img :src="bank.bank_logo_url" />
    </div>
    <div class="py-3 px-4" style="width: 100%">
      <div class="bank--info-name mb-2">
        {{ bank.name }}
      </div>
      <div class="bank--info-description">{{ bank.description }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: ['bank'],
  computed: {
    ...mapState({}),
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.bank--info {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 16px;
  box-shadow: $box-shadow-hover;

  .bank--info-image {
    img {
      width: 120px;
      height: 120px;
      object-fit: contain;
      border-bottom-left-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
      padding: 8px;
    }
  }

  .bank--info-name {
    font-size: $font-xl;
  }

  .bank--info-description {
    font-size: $font-md;
    color: #666666;
  }
}
</style>
